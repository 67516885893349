import { createGlobalStyle } from 'styled-components'

import webkitScrollbar from '@styles/scrollbar/webkitScrollbarPrimary'

const GlobalStyles = createGlobalStyle<{ $isVersionPage?: null | string }>`
  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    min-height: 100%;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    background: ${p => p.theme.colors.backgroundPage};
    -webkit-text-size-adjust: none;

    #__next {
      height: inherit;
      width: inherit;
    }
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: ${p => (p.$isVersionPage ? 'inherit' : p.theme.colors.fontMain)};
    ${webkitScrollbar};
  }

  path,
  svg {
    color: unset;
  }
  
  svg {
    fill: currentColor;
  }

  *[viewBox="0 0 24 24"] {
      height: 24px;
  } 
  
  a {
    color: inherit;
    text-decoration: none;
  }

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 9000s 0s;
      caret-color: ${p => p.theme.colors.fontMain};
      -webkit-text-fill-color: ${p => p.theme.colors.fontMain};
      -webkit-box-shadow: 0 0 0 30px ${p => p.theme.colors.backgroundInput} inset !important;
    }
  }

  .rc-virtual-list-scrollbar {
    width: 4px !important;
    background: ${p => p.theme.colors.backgroundBlueContainer};
    border-radius: 2px;
  }

  .rc-virtual-list-scrollbar-thumb {
    background-color: ${p => p.theme.colors.primary} !important;
  }

  button {
      border: none;
      background: none;
      cursor: pointer;
  }
`

export default GlobalStyles
