export const colorsList = {
  additionalBlueLogo: '#0b2e7b',
  additionalLogo: '#79591f',
  black: '#000000',
  black0: '#00000000',
  black05: '#0000000D',
  black10: '#0000001A',
  black100: '#000000FF',
  black25: '#00000040',
  black30: '#0000004D',
  black40: '#00000066',
  black50: '#00000080',
  black60: '#00000099',
  black80: '#000000CC',
  blackBackground: '#030303',
  blackBlock: '#292929',
  //blackBlue: '#232631', never used
  blackLight: '#262A35',
  blackLite: '#191919',
  blackModern: '#1a1a1a',
  blackOpacity05: '#0000000D',
  blackOpacity25: '#00000040',
  blue100: '#165DF6',
  blueContrast: '#3573F9',
  blueDark: '#2E4A8A',
  blueDeep: '#141c2e',
  blueHover: '#DEEBFF',
  blueLite: '#DFE8FD',
  blueNight: '#00284f',
  blueSelected: '#2684FF',
  darkCharcoal: '#313131',
  deepGrey: '#161A25',
  error: '#D22C35',
  errorLight: '#faeae9',
  errorV4: '#ba0000',
  gradientBlue: '#165DF626',
  gradientBlueOpacity: '#165DF600',
  green: '#62b423',
  green2: '#07b54a',
  greenDark: '#00783E',
  greenHarp: '#e5f1ec',
  grey: '#2B2F3C',
  grey04: '#0000000a',
  grey36: '#363636',
  grey50: '#2B2F3C80',
  grey6A: '#6A6A6A',
  grey8D: '#8D8D8D',
  grey8DOpacity50: '#8D8D8D80',
  greyAdditionalTextDark: '#E6E6E6',
  greyAdditionalTextLight: '#818181',
  greyArgent: '#BFBFBF',
  greyB3: '#B3B3B3',
  greyBlock: '#f2f2f2',
  greyBorder: '#ADBCDD33',
  greyBorderLight: '#CDCDCD',
  greyCarbon: '#707070',
  greyCobalt: '#808080',
  greyCod: '#1E1E1E',
  greyCommon: '#383D47',
  greyD9: '#D9D9D9',
  greyE5: '#e5e5e5',
  greyF4: '#F4F4F4',
  greyFA: '#FAFAFA',
  //greyDark: '#303030',  never used
  greyFrosty: '#F6F8FE',
  greyIcon: '#C8D4CE',
  greyLight: '#F7F7F7',
  greyLightF6: '#F6F6F6',
  greyMedium: '#E8EFFE',
  greyPureWhite: '#F9F9F9',
  greySilver: '#E8E8E8',
  greyTrain: '#dcdcdc',
  mainDark: '#0D101A',
  mainDarkGrey: '#969BA9',
  overflowOpacityDark: '#000000D9',
  overflowOpacityLight: '#FFFFFFD9',
  red: '#E53122',
  redDark: '#b50000',
  redV4: '#e90303',
  semiGrey: '#202531',
  shadow: '#07090F',
  transparentBlack: '#000000BF',
  white: '#FFFFFF',
  whiteOpacity10: '#FFFFFF1A',
  whiteOpacity25: '#FFFFFF40',
  whiteOpacity50: '#ffffff80',
  //yellow: '#ffae00',  never used
  whiteOpacity60: '#FFFFFF99',
  whiteOpacity80: '#FFFFFFCC',
  yellow: '#ffae00',
  yellow10: '#FFAF01',
  yellow100: '#f3b23e',
  yellow30: '#FFD77F',
  yellow40: '#ffb61a',
  yellowBright: '#FFF7E6',
  yellowFrosty: '#fef8ed',
  yellowFrostyDark: '#4d4331',
  yellowLace: '#FDF3E2',
  yellowOpacity10: '#ffaf011a',
}
