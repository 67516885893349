import type { FieldData } from 'rc-field-form/es/interface'

import { setCurrentStep } from '@redux/features/authorization/authorization.slice'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TypedMutationTrigger } from '@reduxjs/toolkit/dist/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'

import type { ApiResponseErrorType } from '@Types/api/responseError'
import type { ResponseErrorType } from '@Types/common/responseError'
import { AuthorizationStep } from '@components/authorization/constant/steps'
import type { RegistrationCheckEmailFormType } from '@utils/forms/authorization/registration'
import { RegistrationFieldsName } from '@utils/forms/authorization/registration'

import type { CheckEmailType, ResponseCheckEmailType } from '../../types/login'

export type PostCheckEmailType = TypedMutationTrigger<
  ResponseCheckEmailType,
  CheckEmailType,
  BaseQueryFn<FetchArgs | string, unknown, ApiResponseErrorType, NonNullable<unknown>, FetchBaseQueryMeta>
>

export default createAsyncThunk<
  void,
  {
    data: RegistrationCheckEmailFormType
    postCheckEmail: PostCheckEmailType
    setErrors: (fields: FieldData[]) => void
  },
  { state: RootState }
>('authorization/registrationCheckEmail', ({ data, postCheckEmail, setErrors }, { dispatch }) => {
  postCheckEmail({ agree_policy: 1, email: data.email || '' })
    .unwrap()
    .then(response => {
      const { message, success } = response

      if (success) {
        dispatch(setCurrentStep(AuthorizationStep.LoginExtended))
      } else {
        setErrors([{ errors: [message], name: RegistrationFieldsName.Email }])
      }
    })
    .catch((error: ResponseErrorType) => {
      if (error.status === 404) {
        dispatch(setCurrentStep(AuthorizationStep.Registration))
      } else {
        setErrors([{ errors: [error.data.message], name: RegistrationFieldsName.Email }])
      }
    })
})
